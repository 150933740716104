import React, { useEffect, Fragment } from "react"
import ImageDarkTextRightWithButton from "../components/imageDarkTextRightWithButton"
import ImageDarkTextLeftWithButton from "../components/imageDarkTextLeftWithButton"
import CTABusiness from "../../../general/ctaBusiness"
import InnerPageHeader from "../components/innerPageHeader"
import { scrollToElement } from "../../../../../utility/utils"
import DarkBelowFoldCards from "../../../general/darkBelowFoldCards";
import FAQList from '../../../general/faqsList';
import MoreForYouComponent from "../../../general/more-for-you"
import { moreForYou2 } from "../features"

// hero image
import EntryIllustration from '../../../../../../assets/img/subscription-hero-image.svg'
import EntryIllustrationBlur from "../../../../../../assets/img/business/blur/airtime-hero-blur.svg"
import PoleWifiIcon from "../../../../../../assets/img/kuda-pole-wifi.inline.svg"
import MobileNetworkIcon from "../../../../../../assets/img/kuda-mobile-network.inline.svg"
import NetworkIcon from "../../../../../../assets/img/kuda-network.inline.svg"

import ChooseNetworkIllustration from "../../../../../../assets/img/business/airtime/chose-network-illustration.svg"
import InternetIllustration from "../../../../../../assets/img/business/airtime/internet-illustration.svg"
import AirtimeTopUpIllustration from "../../../../../../assets/img/business/airtime/airtime-topup-illustration.svg"
import SendAirtimeIllustration from "../../../../../../assets/img/business/airtime/send-airtime-illustration.svg"

import { Img } from "react-image";

const entryContent = {
  title: (<span>
    <span className="color-secondary">Recharge</span>  your business phones easily on the Kuda app.

  </span>),
  subtitle: "Buy Airtel, Glo, MTN and 9Mobile airtime and internet data any time.",
  name: "Download Kuda Business",
  url: "https://kudabusiness.onelink.me/epwz/jceed731",
}

const businessTopFeatures = [
  {
    icon: <PoleWifiIcon />,
    text: `Buy airtime from any Nigerian mobile network.`,
  },
  {
    icon: <MobileNetworkIcon />,
    text: `Top up your business phone in a few seconds.
    `,
  },
  {
    icon: <NetworkIcon />,
    text: `Subscribe to your favourite mobile and modem data plans easily.`,
  },
]

const najiaNetwork = {
  title: "Find all Nigerian mobile networks on Kuda.",
  subtitle: "Buy Airtel, Glo, MTN and 9Mobile airtime and internet data for business directly from your account.",
  url: "https://kudabusiness.onelink.me/epwz/jceed731",
  name: "Join Kuda Business",
  illustration: (
    <Img src={ChooseNetworkIllustration} className="kuda-business-image" />
  ),
}

const favoritePlan = {
  title: "Subscribe to your favourite data plan for business.",
  subtitle: "Buy any data plan quickly without an airtime recharge.",
  url: "https://kudabusiness.onelink.me/epwz/jceed731/",
  name: "Download to Buy Data",
  illustration: (
    <Img src={InternetIllustration} className="kuda-business-image" />

  ),
}

const airtimeTopup = {
  title: "Get your airtime top-up in seconds.",
  subtitle: "You’ll get your airtime or data soon after you complete your purchase on the app.",
  url: "https://kudabusiness.onelink.me/epwz/jceed731",
  name: "Join Kuda Business",
  illustration: (
    <Img src={AirtimeTopUpIllustration} className="kuda-business-image" />

  ),
}

const sendAirtime = {
  title: "Send airtime to your staff.",
  subtitle: "Recharge any Nigerian phone number from your Kuda app even when you’re abroad.",
  url: "https://kudabusiness.onelink.me/epwz/jceed731/",
  name: "Download to Buy Airtime",
  illustration: (
    <Img src={SendAirtimeIllustration} className="kuda-business-image" />

  ),
}

const questions = [
  {
    heading: "How do I buy airtime on Kuda Business?",

    list: (
      <span className="flex flex-column mt-2">
        <span className="faq-description f-16">To buy airtime, follow the steps below:</span>

        <span className="pt-3 career-message-margin-bottom ">
          <div className="flex flex-column  leading-md-6 f-md-16">
            <div className="mb-3">1. Sign in to the Kuda Business web or mobile app, then tap <b>Payments.</b></div>
            <div className="mb-3">2. Tap <b>Buy Airtime.</b></div>
            <div className="mb-3">3. Type in the amount of airtime you’d like to buy, choose the phone network, then type in the phone number and tap <b>Buy</b>.</div>
            <div className="mb-3">4. Complete your airtime recharge with your transaction ID, fingerprint or Face ID.</div>
          </div>
        </span>

      </span>),
    index: 1
  },
  {
    heading: "How do I buy internet data on Kuda Business?",

    list: (
      <span className="flex flex-column mt-2">
        <span className="faq-description f-16">To buy internet data, follow the steps below:</span>

        <span className="pt-3 career-message-margin-bottom ">
          <div className="flex flex-column  leading-md-6 f-md-16">
            <div className="mb-3">1. Sign in to the Kuda Business web or mobile app, then tap <b>Payments.</b></div>
            <div className="mb-3">2. Tap <b>Pay A Bill.</b></div>
            <div className="mb-3">3. Tap <b>Internet.</b></div>
            <div className="mb-3">4. Choose your mobile network or internet service provider.</div>
            <div className="mb-3">5. Choose your internet subscription package (data plan).</div>
            <div className="mb-3">6. Type in your phone number or customer ID, then tap <b>Pay.</b></div>
            <div className="mb-3">7. Confirm your data purchase with your transaction PIN, fingerprint or Face ID.</div>
          </div>
        </span>

      </span>),
    index: 2
  },
  {
    heading: "Can I recharge a Nigerian phone number with the Kuda app when I’m abroad?",
    list: (
      <div className="flex flex-column mt-2">
        <span className="faq-description f-16">Yes, you can send airtime or internet data to any Nigerian mobile phone number
          from your Kuda app when you’re abroad. You can also buy commercial internet plans for your office modem or router wherever you are.

        </span>

      </div>),
    index: 3
  },

  {
    heading: "Which mobile networks can I buy airtime and data from on the Kuda app?",
    list: (
      <div className="flex flex-column mt-2">
        <div className="faq-description f-16">
          You can buy airtime and internet data from all Nigerian mobile networks - Airtel, Glo, MTN and 9Mobile - on the Kuda app. You can also buy internet data from Smile and Spectranet on the app.
        </div>

      </div>),
    index: 4
  },
  {
    heading: "When can I use the airtime or data that I buy on the Kuda app?",
    list: (
      <span className="flex flex-column mt-2">

        <span className="faq-description mb-3 f-16">You’ll get your airtime recharge in a few seconds after you complete your purchase on the Kuda app, and you can use the airtime immediately. </span>

        <span className="faq-description f-16">Personal and commercial internet data subscriptions sometimes take up to a few minutes to become active, but you’ll be able to use your data as soon as your subscription is active.</span>
      </span>),
    index: 5
  }
]


const AirtimeAndInternet = () => {
  useEffect(() => {
    scrollToElement()
    window.addEventListener("scroll", function () {
      scrollToElement()
    })
  }, [])

  return (
    <Fragment>
      <InnerPageHeader
        title={entryContent.title}
        subtitle={entryContent.subtitle}
        buttonName={entryContent.name}
        buttonUrl={entryContent.url}
        entryIllustration={EntryIllustration}
        fallback={<Img src={EntryIllustrationBlur} className="hero-illustration" />}

      />
      <DarkBelowFoldCards topFeatures={businessTopFeatures} />

      <ImageDarkTextLeftWithButton
        title={najiaNetwork.title}
        subtitle={najiaNetwork.subtitle}
        illustration={najiaNetwork.illustration}
        name={najiaNetwork.name}
        url={najiaNetwork.url}
        isExternal={true}
      />
      <ImageDarkTextRightWithButton
        title={favoritePlan.title}
        subtitle={favoritePlan.subtitle}
        illustration={favoritePlan.illustration}
        name={favoritePlan.name}
        url={favoritePlan.url}
        isExternal={true}
      />
      <ImageDarkTextLeftWithButton
        title={airtimeTopup.title}
        subtitle={airtimeTopup.subtitle}
        illustration={airtimeTopup.illustration}
        name={airtimeTopup.name}
        url={airtimeTopup.url}
        isExternal={true}
      />
      <ImageDarkTextRightWithButton
        title={sendAirtime.title}
        subtitle={sendAirtime.subtitle}
        illustration={sendAirtime.illustration}
        name={sendAirtime.name}
        url={sendAirtime.url}
        isExternal={true}
      />
      <FAQList title={"Airtime & Internet Data FAQs"} questions={questions} />

      <MoreForYouComponent moreForYou={moreForYou2} />
      <CTABusiness />
    </Fragment>
  )
}

export default AirtimeAndInternet
